import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { RouteNames } from '@constants/navigation';
import { BankOperationTypesList } from '@constants/savings';

import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { getLanguage } from '@modules/label/selectors';
import { runManager } from '@modules/moduleManager';
import { RootNavigation } from '@navigation/RootNavigation';

import {
  downloadDocumentMandatRCSuccess,
  getBankDataSuccess,
  initBankDataRCSuccess,
  initBankDataSuccess,
  registerBankDataSuccess,
  removeDocumentBankDataFailure,
  removeDocumentBankDataSuccess,
  setUpdateFinished,
  uploadDocumentBankDataFailure,
  uploadDocumentBankDataSuccess
} from './actions/actionsCreators';
import {
  BankDetailsActionsType,
  InitBankDataRequestAction,
  RegisterBankDataRequestAction,
  RemoveDocumentBankDataRequestAction,
  UploadDocumentBankDataRequestAction
} from './actions/actionsTypes';
import {
  getContractId,
  getDocument,
  getDocumentId,
  getJourneyId,
  getMaxFileSizes,
  getSelectedAccountType,
  getSelectedDomiciliationCountry,
  getESBankData,
  getSelectedPlanFamily,
  getShouldUploadDocByDoc,
  getWithEREOTP,
  getWithQuicksignOTP,
  getAllowedFileExtensions,
  getDocumentData,
  getSelectedDocaposteChanel
} from './selectors';
import {
  fetchDownloadDocumentMandatRc,
  fetchGetBankData,
  fetchInitBankDataES,
  fetchInitBankDataRC,
  registerWithAllDocuments,
  registerWithEREOTP,
  registerWithQuicksignOTP,
  removeDocumentBankData,
  signMandatRcAndUploadIban,
  uploadDocumentBankData
} from './services';
import { BankDetailsInitType, RegisterParameter } from './types';
import { checkFile } from './utils';
import { getApplicationSettings } from '@modules/settings/selectors';
import { blobToBase64 } from '@ere-uilib/utils';
import { FilesCategoriesEnum } from '@ere-uilib/enums';
import { PlanFamillyList } from "@constants/common";

function* getBankData(): any {
  const companyId = yield select(getUsedCompanyId);
  // Use For Mock Data
  // yield delay(1500);
  // const response = {
  //   data: mockBankData
  // };

  const response = yield call(fetchGetBankData, companyId);
  yield put(getBankDataSuccess(response.data));
}

function* getBankDataSagas(): any {
  yield takeLatest(
    BankDetailsActionsType.GET_BANK_DATA_REQUEST,
    runManager(getBankData, BankDetailsActionsType.GET_BANK_DATA_FAILURE)
  );
}

function* initBankData({ iban = '', bic = '' }: InitBankDataRequestAction): any {
  const selectedPlanFamily = yield select(getSelectedPlanFamily);
  const companyId = yield select(getUsedCompanyId);
  let data: BankDetailsInitType = {
    journeyId: null,
    contractId: null,
    fileMaxSize: null,
    totalFileMaxSize: null
  };

  if (selectedPlanFamily === PlanFamillyList.ES) {
    const { fileMaxSize = 0, totalFileMaxSize = 0 } = yield select(getESBankData);
    const { signatureAllowed } = yield select(getESBankData);
    const selectedCountry = yield select(getSelectedDomiciliationCountry);
    const updateType = yield select(getSelectedAccountType);
    if (signatureAllowed && selectedCountry) {
      const { data: response } = yield call(
        fetchInitBankDataES,
        companyId,
        selectedCountry,
        updateType
      );
      data = {
        ...response,
        fileMaxSize: response.fileMaxSize || fileMaxSize,
        totalFileMaxSize: response.totalFileMaxSize || totalFileMaxSize
      };
    }
    yield put(initBankDataSuccess(data));
    yield RootNavigation.navigate(RouteNames.BankDetailsStack, {
      screen: RouteNames.BankSetDocuments
    });
  }

  if (selectedPlanFamily === PlanFamillyList.RC) {
    const { CBRCTailleFichier = 0 } = yield select(getApplicationSettings);
    const { data: response } = yield call(fetchInitBankDataRC, companyId, iban, bic);
    data = {
      ...response,
      fileMaxSize: CBRCTailleFichier,
      totalFileMaxSize: CBRCTailleFichier
    };
    yield put(initBankDataRCSuccess(data));
    yield RootNavigation.navigate(RouteNames.BankDetailsStack, {
      screen: RouteNames.BankSignatureRC
    });
  }
}

function* initBankDataSagas(): any {
  yield takeLatest(
    BankDetailsActionsType.INIT_BANK_DATA_REQUEST,
    runManager(initBankData, BankDetailsActionsType.INIT_BANK_DATA_FAILURE)
  );
}

function* uploadDocumentBankDetails({
  documentKey,
  file
}: UploadDocumentBankDataRequestAction): any {
  try {
    const { fileMaxSize } = yield select(getMaxFileSizes);
    const allowedFileExtensions = yield select(getAllowedFileExtensions);
    const shouldUploadDocByDoc = yield select(getShouldUploadDocByDoc);
    const companyId = yield select(getUsedCompanyId);
    const journeyId = yield select(getJourneyId);

    checkFile(file, fileMaxSize, allowedFileExtensions);

    let documentId = '';

    if (shouldUploadDocByDoc) {
      const formData = new FormData();
      formData.append('attachedFileContent', file.base64);
      formData.append('docType', documentKey);

      const response = yield call(uploadDocumentBankData, companyId, journeyId, formData);

      documentId = response?.data;
    }

    yield put(uploadDocumentBankDataSuccess(documentKey, documentId, file));
  } catch (err) {
    yield put(uploadDocumentBankDataFailure(documentKey));
  }
}

function* uploadDocumentBankDetailsSagas(): any {
  yield takeEvery(
    BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_REQUEST,
    runManager(uploadDocumentBankDetails, BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_FAILURE)
  );
}

function* signMandatRc(): any {
  const journeyId = yield select(getJourneyId);
  const companyId = yield select(getUsedCompanyId);
  const ibanFile = yield select(getDocumentData(FilesCategoriesEnum.IBAN));
  const formData = new FormData();
  const selectedOTP = yield select(getSelectedDocaposteChanel);

  if (ibanFile) formData.append('ibanFile', ibanFile.base64);
  const response = yield call(signMandatRcAndUploadIban, companyId, journeyId, formData);
  const isOK = response?.data?.transactionStatus === 'OK';
  yield put(setUpdateFinished(isOK));
}

function* signMandatRcSagas(): any {
  yield takeLatest(
    BankDetailsActionsType.SIGN_MANDAT_RC_REQUEST,
    runManager(signMandatRc, BankDetailsActionsType.SIGN_MANDAT_RC_FAILURE)
  );
}

function* downloadDocumentMandatRc(): any {
  const companyId = yield select(getUsedCompanyId);
  const journeyId = yield select(getJourneyId);
  const response = yield call(fetchDownloadDocumentMandatRc, companyId, journeyId);
  const base64data = yield call(blobToBase64, response);

  yield put(downloadDocumentMandatRCSuccess(base64data));
}

function* downloadDocumentMandatRcSagas(): any {
  yield takeLatest(
    BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_REQUEST,
    runManager(downloadDocumentMandatRc, BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_FAILURE)
  );
}

function* removeDocumentBankDetails({ documentKey }: RemoveDocumentBankDataRequestAction): any {
  try {
    const journeyId = yield select(getJourneyId);
    const companyId = yield select(getUsedCompanyId);
    const documentId = yield select(getDocumentId(documentKey));
    const shouldUploadDocByDoc = yield select(getShouldUploadDocByDoc);

    if (documentId && shouldUploadDocByDoc)
      yield call(removeDocumentBankData, companyId, journeyId, documentId);

    yield put(removeDocumentBankDataSuccess(documentKey));
  } catch {
    yield put(removeDocumentBankDataFailure(documentKey));
  }
}

function* removeDocumentBankDetailsSagas(): any {
  yield takeEvery(
    BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_REQUEST,
    runManager(removeDocumentBankDetails, BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_FAILURE)
  );
}

function* registerBankDetails({ iban, bic }: RegisterBankDataRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const journeyId = yield select(getJourneyId);
  const contractId = yield select(getContractId);
  const language = yield select(getLanguage);
  const countryCode = yield select(getSelectedDomiciliationCountry);
  const bankAccountUpdateType = yield select(getSelectedAccountType);
  const shouldUploadDocByDoc = yield select(getShouldUploadDocByDoc);
  const withQuicksignOTP = yield select(getWithQuicksignOTP);
  const withEREOTP = yield select(getWithEREOTP);
  const documents = yield select(getDocument);

  let registerFn = registerWithQuicksignOTP;

  let payload: RegisterParameter | FormData = {
    iban,
    bic,
    bankAccountUpdateType,
    contractId,
    countryCode,
    languageCode: language.toUpperCase()
  };

  if (!shouldUploadDocByDoc) {
    registerFn = registerWithAllDocuments;
    const formData = new FormData();

    Object.keys(documents).forEach((documentKey, index) =>
      formData.append(`attachedFilesList[${index}]`, documents?.[documentKey]?.file?.base64)
    );

    formData.append('updateType', bankAccountUpdateType);
    formData.append('iban', iban);
    formData.append('bic', bic);
    payload = formData;
  } else if (withQuicksignOTP) {
    registerFn = registerWithQuicksignOTP;
  } else if (withEREOTP || bankAccountUpdateType === BankOperationTypesList.VIREMENT) {
    registerFn = registerWithEREOTP;
  }

  const { data } = yield call(registerFn, companyId, payload, journeyId);

  yield put(registerBankDataSuccess(data));

  // can start quicksign otp flow => if url exists in payload
  if (typeof data === 'string' && data.length > 0) {
    yield put(setUpdateFinished(null));

    return yield RootNavigation.navigate(RouteNames.BankDetailsStack, {
      screen: RouteNames.BankSignature
    });
  }

  // dispatch success error
  yield put(setUpdateFinished(true));
}

function* registerBankDetailsSagas(): any {
  yield takeLatest(
    BankDetailsActionsType.REGISTER_BANK_DATA_REQUEST,
    runManager(registerBankDetails, BankDetailsActionsType.REGISTER_BANK_DATA_FAILURE)
  );
}

export function* BankDetailsSagas() {
  yield all([
    fork(getBankDataSagas),
    fork(initBankDataSagas),
    fork(uploadDocumentBankDetailsSagas),
    fork(removeDocumentBankDetailsSagas),
    fork(registerBankDetailsSagas),
    fork(downloadDocumentMandatRcSagas),
    fork(signMandatRcSagas)
  ]);
}
